import React, { useMemo, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import Compressor from 'compressorjs';
import PropTypes from 'prop-types';
import { consoleLogDetails } from '../../../helpers/consoleLogDetails';
import settings from '../../../config/settings';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const focusedStyle = {
  borderColor: '#7E22CE',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

export default function DropzoneFileUploader(props: any) {
  // Create the dispatch function
  const dispatch = useDispatch();
  const { fileUploadType, heading = 'Upload Image' } = props;

  // Use the accepted files to dispatch them to server
  const onDrop = useCallback((file) => {
    // eslint-disable-next-line no-new
    new Compressor(file[0], {
      quality: settings.images.quality,
      maxWidth: settings.images.maxWidth,

      // The compression process is asynchronous,
      // which means you have to access the `result` in the `success` hook function.
      success(result) {
        // convert the blob to a file
        const newFile = new File([result], file[0].name, {
          type: file[0].type,
        });
        // eslint-disable-next-line react/destructuring-assignment
        dispatch(props.onDropAction(newFile, fileUploadType));
      },
      error(err) {
        console.log(err.message);
      },
    });
  }, []);

  // Destructure props
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      maxFiles: 1,
      accept: 'image/jpeg, image/png, image/jpg',
      onDrop,
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <section className="container">
      {/* @ts-ignore */}
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <h4 style={{ textAlign: 'center', marginBottom: '1rem' }}>{heading}</h4>
        <p style={{ textAlign: 'center' }}>
          Drag drop some files here, or click to select files
        </p>
      </div>
    </section>
  );
}

DropzoneFileUploader.propTypes = {
  fileUploadType: PropTypes.string,
  heading: PropTypes.string,
  onDropAction: PropTypes.func,
};
