const settings = {
  user: {
    defaultRedirect: '',
    redirectWhenAuth: '',
  },
  app: {
    apiEndpoint: 'https://api.cloudaffle.com/', // http://localhost:3200/ , https://api.cloudaffle.com/,
    timeToResetErrors: 10000,
    tokenRefreshTime: 600000,
  },
  aws: {
    cloudFrontUrl: '//d3b1xihvmft23d.cloudfront.net',
  },
  images: {
    maxWidth: 1600,
    quality: 0.6,
  },
};

export default settings;
