import { put, takeEvery, delay } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { AnyAction } from 'redux';

// Import custom modules
import ActionTypes from '../../actionTypes';
import AxiosBaseInstance from '../../../config/axios/axiosBaseInstance';
import settings from '../../../config/settings';
import { clearAllErrors } from '../../actions/genericFormActions/genericFormActions';
import getCurrentUser from '../../../config/getCurrentUser/getCurrentUser';
import { User } from '../../reducers/userReducers/userReducer';
import {
  featuredImageUploadError,
  updateFeaturedImageUrl,
} from '../../actions/postFormsActions/postSettingsFormActions';
import { FileUploadTypes } from '../../../enums/FileUploadTypes';

function* initiateImageUpload(action: AnyAction) {
  const bodyFormData = new FormData();
  bodyFormData.append('file', action.file);
  try {
    const user: User = yield getCurrentUser();
    const response: AxiosResponse = yield AxiosBaseInstance.post(
      'uploads/image',
      bodyFormData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${user.accessToken}`,
        },
      }
    );
    if (response.status === 201) {
      // If uploading featured image
      if (action.uploadType === FileUploadTypes.featuredImage) {
        yield put(updateFeaturedImageUrl(response.data.path));
      }
    }
  } catch (error) {
    // If uploading featured image
    if (action.uploadType === FileUploadTypes.featuredImage) {
      yield put(featuredImageUploadError());
    }
    yield delay(settings.app.timeToResetErrors);
    yield put(clearAllErrors());
  }
}

export function* initiateImgaeFileUpload() {
  yield takeEvery(ActionTypes.uploadImageFile, initiateImageUpload);
}
